<template>
  <div></div>
</template>

<script>
import { SET_BREADCRUMB } from "../../core/services/store/mutations.type";
export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  }
};
</script>
